<template>
  <PageTitle title="外構リフォーム" />
  <PageDescription pageName="外構リフォーム" />
  <div class="wrap page-images">
    <img src="@/assets/exterior-renovation/exterior_001.webp" alt="外構リフォームの施工イメージ画像1" class="sample-img">
    <img src="@/assets/exterior-renovation/exterior_002.webp" alt="外構リフォームの施工イメージ画像2" class="sample-img">
    <img src="@/assets/exterior-renovation/exterior_003.webp" alt="外構リフォームの施工イメージ画像3" class="sample-img">
    <img src="@/assets/exterior-renovation/exterior_004.webp" alt="外構リフォームの施工イメージ画像4" class="sample-img">
    <img src="@/assets/exterior-renovation/exterior_005.webp" alt="外構リフォームの施工イメージ画像5" class="sample-img">
    <img src="@/assets/exterior-renovation/exterior_006.webp" alt="外構リフォームの施工イメージ画像6" class="sample-img">
    <img src="@/assets/exterior-renovation/exterior_007.webp" alt="外構リフォームの施工イメージ画像7" class="sample-img">
    <img src="@/assets/exterior-renovation/exterior_008.webp" alt="外構リフォームの施工イメージ画像8" class="sample-img">
    <img src="@/assets/exterior-renovation/exterior_009.webp" alt="外構リフォームの施工イメージ画像9" class="sample-img">
  </div>
  <Reason />
  <Price />
  <ViewMore />
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import PageDescription from '@/components/Ui/PageDescription.vue';
import Reason from '@/components/Home/Reason.vue';
import Price from '@/components/Home/Price.vue';
import ViewMore from '@/components/Ui/ViewMore.vue';

export default {
  name: "ExteriorRenovation",
  components: {
    PageTitle,
    PageDescription,
    Reason,
    Price,
    ViewMore,
  }
}
</script>

<style lang="scss" scoped>
</style>
