<template>
  <PageTitle title="新築外構" />
  <PageDescription pageName="新築外構" />
  <div class="wrap page-images">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku001.webp" alt="新築外構の施工イメージ画像1" class="sample-img">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku002.webp" alt="新築外構の施工イメージ画像2" class="sample-img">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku003.webp" alt="新築外構の施工イメージ画像3" class="sample-img">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku004.webp" alt="新築外構の施工イメージ画像4" class="sample-img">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku005.webp" alt="新築外構の施工イメージ画像5" class="sample-img">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku006.webp" alt="新築外構の施工イメージ画像6" class="sample-img">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku007.webp" alt="新築外構の施工イメージ画像7" class="sample-img">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku008.webp" alt="新築外構の施工イメージ画像8" class="sample-img">
    <img src="@/assets/new-exterior/azuma_sekou_shintiku009.webp" alt="新築外構の施工イメージ画像9" class="sample-img">
  </div>
  <Reason />
  <Price />
  <ViewMore />
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import PageDescription from '@/components/Ui/PageDescription.vue';
import Reason from '@/components/Home/Reason.vue';
import Price from '@/components/Home/Price.vue';
import ViewMore from '@/components/Ui/ViewMore.vue';

export default {
  name: "NewExterior",
  components: {
    PageTitle,
    PageDescription,
    Reason,
    Price,
    ViewMore,
  }
}
</script>

<style lang="scss" scoped>
</style>
