import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import NewExterior from '../views/NewExterior.vue';
import WoodDeck from '../views/WoodDeck.vue';
import Terrace from '../views/Terrace.vue';
import Fence from '../views/Fence.vue';
import GardenRoom from '../views/GardenRoom.vue';
import Carport from '../views/Carport.vue';
import Garage from '../views/Garage.vue';
import ExteriorRenovation from '../views/ExteriorRenovation.vue';
import store from "@/store";
import { setTitle, setDescription } from "@/mixins/index.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "あずま造園土木｜瀬戸市の外構工事・エクステリア",
      description:
        "瀬戸市の株式会社あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに、毎年200件の外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料。ぜひお気軽にお問い合わせください。",
    },
  },
  {
    path: "/new-exterior",
    name: "new-exterior",
    component: NewExterior,
    meta: {
      title: "新築外構の施工事例｜瀬戸の外構工事・エクステリアあずま造園土木",
      description:
        "お値打ち価格の新築外構工事なら瀬戸市の株式会社あずま造園土木へ。新築外構の施工事例を紹介しています。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。",
    },
  },
  {
    path: "/wood-deck",
    name: "wood-deck",
    component: WoodDeck,
    meta: {
      title: "ウッドデッキの施工事例｜瀬戸の外構工事・エクステリアあずま造園土木",
      description:
        "お値打ち価格のテラスの工事なら瀬戸市の株式会社あずま造園土木へ。テラスの施工事例を紹介しています。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。",
    },
  },
  {
    path: "/terrace",
    name: "terrace",
    component: Terrace,
    meta: {
      title: "テラスの施工事例｜瀬戸の外構工事・エクステリアあずま造園土木",
      description:
        "お値打ち価格のテラスの工事なら瀬戸市の株式会社あずま造園土木へ。テラスの施工事例を紹介しています。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。",
    },
  },
  {
    path: "/fence",
    name: "fence",
    component: Fence,
    meta: {
      title: "フェンスの施工事例｜瀬戸の外構工事・エクステリアあずま造園土木",
      description:
        "お値打ち価格のフェンスの工事なら瀬戸市の株式会社あずま造園土木へ。フェンスの施工事例を紹介しています。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。",
    },
  },
  {
    path: "/garden-room",
    name: "garden-room",
    component: GardenRoom,
    meta: {
      title: "ガーデンルームの施工事例｜瀬戸の外構工事・エクステリアあずま造園土木",
      description:
        "お値打ち価格のガーデンルームの工事なら瀬戸市の株式会社あずま造園土木へ。ガーデンルームの施工事例を紹介しています。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。",
    },
  },
  {
    path: "/carport",
    name: "carport",
    component: Carport,
    meta: {
      title: "カーポートの施工事例｜瀬戸の外構工事・エクステリアあずま造園土木",
      description:
        "お値打ち価格のカーポートの工事なら瀬戸市の株式会社あずま造園土木へ。カーポートの施工事例を紹介しています。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。",
    },
  },
  {
    path: "/garage",
    name: "garage",
    component: Garage,
    meta: {
      title: "ガレージの施工事例｜瀬戸の外構工事・エクステリアあずま造園土木",
      description:
        "お値打ち価格のガレージの工事なら瀬戸市の株式会社あずま造園土木へ。ガレージの施工事例を紹介しています。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。",
    },
  },
  {
    path: "/exterior-renovation",
    name: "exterior-renovation",
    component: ExteriorRenovation,
    meta: {
      title: "外構リフォームの施工事例｜瀬戸の外構工事・エクステリアあずま造園土木",
      description:
        "お値打ち価格の外構リフォームの工事なら瀬戸市の株式会社あずま造園土木へ。外構リフォームの施工事例を紹介しています。あずま造園土木は、お値打ち価格と豊富な経験に裏打ちされた対応力を強みに外構とエクステリアの工事をおこなっています。お客様のご要望を叶えること、迅速かつ柔軟に対応することをお約束しています。対応エリアは、瀬戸市・尾張旭市・長久手市・守山区・春日井市・多治見市。見積もりは無料です。",
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition === true) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName("head");
  let gfjScript = document.createElement("script");
  gfjScript.type = "application/ld+json";
  if (to.meta.gfjScriptContent !== undefined) {
    gfjScript.innerHTML = to.meta.gfjScriptContent;
    documentHead[0].appendChild(gfjScript);
    //↑gfj用
  }
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router;