<template>
  <div class="page-title">
    <h1>{{ title }}の施工事例</h1>
    <img src="@/assets/page/sekou_ira002.webp" alt="" class="ira002" />
    <img src="@/assets/page/sekou_ira003.webp" alt="" class="ira003" />
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: String,
  }
}
</script>

<style lang="scss" scoped>
  .page-title {
    height: 260px;
    margin-top: 8vh;
    background-color: var(--sub1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 767px) {
      margin-top: 10vh;
      height: 180px;
    }
    h1 {
      font-size: 40px;
      color: var(--white);
      text-align: center;
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    img {
      position: absolute;
      width: 180px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 130px;
      }
      @media screen and (max-width: 767px) {
        width: 120px;
      }
    }
    .ira002 {
      left: 3%;
      bottom: 20px;
      @media screen and (max-width: 767px) {
        bottom: -50px;
      }
    }
    .ira003 {
      right: 3%;
      bottom: 20px;
      @media screen and (max-width: 767px) {
        bottom: -50px;
      }
    }
  }
</style>