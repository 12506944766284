<template>
  <PageTitle title="ガレージ" />
  <PageDescription pageName="ガレージ" />
  <div class="wrap page-images">
    <img src="@/assets/garage/garage_001.webp" alt="ガレージの施工イメージ画像1" class="sample-img">
    <img src="@/assets/garage/garage_002.webp" alt="ガレージの施工イメージ画像2" class="sample-img">
    <img src="@/assets/garage/garage_003.webp" alt="ガレージの施工イメージ画像3" class="sample-img">
    <img src="@/assets/garage/garage_004.webp" alt="ガレージの施工イメージ画像4" class="sample-img">
    <!-- <img src="@/assets/garage/garage_005.webp" alt="ガレージの施工イメージ画像5" class="sample-img">
    <img src="@/assets/garage/garage_006.webp" alt="ガレージの施工イメージ画像6" class="sample-img">
    <img src="@/assets/garage/garage_007.webp" alt="ガレージの施工イメージ画像7" class="sample-img">
    <img src="@/assets/garage/garage_008.webp" alt="ガレージの施工イメージ画像8" class="sample-img">
    <img src="@/assets/garage/garage_009.webp" alt="ガレージの施工イメージ画像9" class="sample-img"> -->
  </div>
  <Reason />
  <Price />
  <ViewMore />
</template>

<script>
import PageTitle from '@/components/Ui/PageTitle.vue';
import PageDescription from '@/components/Ui/PageDescription.vue';
import Reason from '@/components/Home/Reason.vue';
import Price from '@/components/Home/Price.vue';
import ViewMore from '@/components/Ui/ViewMore.vue';

export default {
  name: "GarageVue",
  components: {
    PageTitle,
    PageDescription,
    Reason,
    Price,
    ViewMore,
  }
}
</script>

<style lang="scss" scoped>
</style>
